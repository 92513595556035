import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const BlogArticle = ({ node }) => {
  return (
    <article className="mb-6 md:mb-16">
      <Link to={`/${node.slug}`}>
        <h1 className="mb-4 text-3xl font-bold">{node.title}</h1>
      </Link>
      <div className="md:flex">
        <div className="" dangerouslySetInnerHTML={{ __html: node.excerpt }} />
        {node.featuredImage && (
          <Img
            fixed={node.featuredImage.node.localFile.childImageSharp.fixed}
            key={node.featuredImage.node.localFile.childImageSharp.fixed.src}
            className="md:w-1/3"
          />
        )}
      </div>
    </article>
  )
}

export default BlogArticle
