import React from "react"
import Seo from "../components/site-metadata"
import { Link, graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import BlogArticle from "../components/blog-article"

const BlogIndex = props => {
  const { data } = props
  const posts = data.allWpPost.nodes
  posts.sort((a, b) => b.isSticky - a.isSticky)
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (
    <div>
      <Seo
        title={`Blog de la Docteure Debord`}
        description={
          "Retrouvez les conseils santé de la Docteure Marie Debord, spécialiste en médecine générale"
        }
      />
      <Header />
      <div className="flex justify-center mx-auto lg:w-2/5">
        <article>
          <h1 className="mt-24 mb-8 text-2xl">Blog</h1>
          {posts.map(node => (
            <BlogArticle key={node.slug} node={node} />
          ))}
        </article>
      </div>
      <ul className="flex flex-wrap items-center justify-center mt-12 pagination">
        {!isFirst && (
          <Link to={prevPage} rel="prev">
            ← Précédent
          </Link>
        )}
        {Array.from({ length: numPages }, (_, i) => (
          <li key={`pagination-number${i + 1}`}>
            <Link
              className="p-2"
              to={`/blog/${i === 0 ? "" : i + 1}`}
              style={{
                textDecoration: "none",
                color: i + 1 === currentPage ? "#ffffff" : "",
                background: i + 1 === currentPage ? "#007acc" : "",
              }}
            >
              {i + 1}
            </Link>
          </li>
        ))}
        {!isLast && (
          <Link to={nextPage} rel="next">
            Suivant →
          </Link>
        )}
      </ul>
      <Footer />
    </div>
  )
}

export default BlogIndex

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allWpPost(limit: $limit, skip: $skip, sort: { fields: date, order: DESC }) {
      nodes {
        title
        excerpt
        slug
        isSticky
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fixed(width: 300, height: 200) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
